@import "~/styles/colors.less";

.textarea {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 12px;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: @background_550;
    border-radius: 4px;
  }

  &.primary {
    border: 1px solid @background_400;
    background-color: @primary_600;
  }
  &.simple {
    border: 1px solid @background_1100;
    background-color: @white;
    color: @black_50 !important;
    & > textarea {
      color: @black_50 !important;

      &:disabled {
        background-color: @background_650 !important;
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }

  &.secondary {
    border: 1px solid @background_1100;
    background-color: @white;

    &.focused {
      border-color: @primary_500;
    }

    & > textarea {
      min-height: unset !important;
      max-height: unset !important;
      height: unset !important;
      color: @black_50 !important;

      &:disabled {
        background-color: @background_650 !important;
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
}

@primary-color: #E2E2E2;