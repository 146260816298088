@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";
@import "~/styles/colors.less";
@import "~/styles/global.less";
@import "react-toastify/dist/ReactToastify.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "./fonts.less";

@primary-color: #E2E2E2;