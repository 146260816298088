@import "~/styles/responsive.less";

.layout {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  // -webkit-overflow-scrolling: touch;
  // -webkit-backface-visibility: hidden;
  // -webkit-perspective: 1000;

  &-children {
    overflow: auto;
    flex: 1;
    padding-top: 78px;
    min-height: calc(100vh - 138px);
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: @medium-mobile) {
    height: auto;
    min-height: 100%;

    &-children {
      overflow: unset;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 0;
      min-height: unset;
    }
  }
}

@primary-color: #E2E2E2;