@import "~/styles/colors.less";
@import "~/styles/responsive.less";

.footer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &-bg {
    background: @background;
    padding: 16px;
  }

  & > .name {
    font-size: 16px;
    font-weight: bold;
    background: @gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 0;
  }

  &-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  &-left {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-right {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
  }

  &-text {
    font-size: 12px;
    color: @background_200;
    margin-bottom: 0;
  }

  @media screen and(max-width: @big-mobile) {
    & > .name,
    &-text {
      text-align: center;
    }

    &-body {
      grid-template-columns: repeat(1, 1fr);
    }

    &-left,
    &-right {
      align-items: center;
    }
  }
}

@primary-color: #E2E2E2;