* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: normal;

  ::selection {
    background-color: @background_100;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background: @background_50;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: @background_450;
}

::-webkit-scrollbar-thumb {
  background-color: @background_550;
}

.input-otp {
  border: 1px solid @background_800;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  width: 40px !important;
  height: 40px;
  transition: border-color 0.25s ease-in-out;
  color: @white;
  background-color: transparent;
  margin: 32px 0;

  &::selection {
    background-color: @background_100;
  }
}

.ant-popover-inner {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.ant-popover-inner,
.ant-popover-arrow-content::before {
  background: @background_900;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000000s ease-in-out 0s;
}

.ant-checkbox-wrapper {
  color: @background_20;
}

.ant-checkbox.ant-checkbox-checked {
  &::after {
    border: none;
  }

  .ant-checkbox-inner {
    border: none;

    &::after {
      border-color: @white;
    }
  }
}

.ant-checkbox-inner::after {
  width: 5px;
  height: 7px;
  left: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @primary_500;
  border-color: transparent;
}

.ant-checkbox-inner {
  background-color: transparent;
  border-radius: 4px;
  border: 1.5px solid @background_800;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: @background_800;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-checkbox-wrapper {
  align-items: normal;

  & > span {
    font-size: 12px;
    color: @background_20;

    &:last-child {
      margin-top: 1px;
    }
  }
}

.slick-track {
  margin: unset;
}

.slick-next {
  right: -50px;
}

.slick-prev {
  left: -50px;
}

.slick-next,
.slick-prev {
  width: 40px;
  height: 40px;

  &::before {
    content: "";
  }
}

.slick-dots.slick-dots-bottom {
  margin: 0 auto;

  & > li {
    & > button {
      &::before {
        display: none;
      }
    }
  }
}

#root {
  height: 100%;
}

.font-vintage-smooth {
  font-family: "Vintage Smooth", sans-serif;
}

.font-norwester {
  font-family: "Norwester", sans-serif;
}

.font-lexend-zetta {
  font-family: "Lexend Zetta", sans-serif;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.quantity-round {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 30px;
  padding: 0 4px;
  color: white;
  font-size: 10px;
  background-color: @red;
  transform: translate(8px, -8px);
  border: 1px solid white;
}

// Table
.ant-table {
  background-color: @primary_700;

  &-wrapper {
    & > .ant-spin-nested-loading {
      .ant-spin.ant-spin-spinning {
        & > .ant-spin-dot.ant-spin-dot-spin {
          & > .ant-spin-dot-item {
            background-color: @primary_500;
          }
        }
      }

      & > .ant-spin-container.ant-spin-blur {
        &::after {
          background-color: unset;
        }
      }
    }
  }

  & > .ant-table-container {
    & > .ant-table-content,
    & > .ant-table-header {
      & > table {
        & > thead {
          & > tr {
            & > th {
              background-color: @background_50;
              border-bottom: none;
              color: @background_100;
              padding: 8px 12px;
              height: 48px;
              font-size: 14px;

              &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
              }

              &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
              }
            }
          }
        }

        & > tbody {
          & > tr {
            &:hover {
              & > td {
                background-color: transparent;
              }
            }

            & > td {
              border-bottom: 1px solid @background_800;
            }

            &:last-child {
              & > td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

.ant-radio-group {
  & > .ant-radio-wrapper {
    & > span {
      &:last-child {
        font-size: 14px;
      }
    }

    & > .ant-radio {
      &.ant-radio-checked {
        & > .ant-radio-inner {
          border-color: @primary_500;

          &::after {
            background-color: @primary_500;
          }
        }
      }
    }
  }
}

// InputNumber
.ant-input-number-group-wrapper {
  // &
  //   > .ant-input-number-wrapper.ant-input-number-group.ant-input-number-wrapper-disabled {
  //   &:hover,
  //   &:focus {
  //     border-color: var(--grey-100) !important;
  //   }

  //   & > .ant-input-number-group-addon {
  //     background-color: rgba(0, 0, 0, 0.04);
  //   }
  // }

  &.focused {
    & > .ant-input-number-wrapper {
      border-color: @primary_500;
    }
  }

  &.disabled {
    & > .ant-input-number-wrapper {
      border-color: @background_1100 !important;
    }
  }

  & > .ant-input-number-wrapper {
    border: 1px solid @background_1100;
    border-radius: 12px;
    transition: border 0.2s;
    overflow: hidden;

    &:hover,
    &:focus {
      border-color: @primary_500;
      transition: border 0.2s;

      & > .ant-input-number {
        border: none;
      }

      & > .ant-input-number-group-addon {
        border: none;
      }
    }

    & > .ant-input-number {
      border: none;
      box-shadow: none !important;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      & > .ant-input-number-handler-wrap {
        display: none;
      }

      & > .ant-input-number-input-wrap {
        & > input {
          height: 56px;
          font-size: 14px;

          &::placeholder {
            color: @background_100;
          }
        }
      }
    }

    & > .ant-input-number-group-addon {
      background: none;
      border: none;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      & > span {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // color: $grey_200;
        font-size: 14px;
        font-weight: semibold;
      }
    }
  }
}

.ant-spin.ant-spin-spinning {
  & > .ant-spin-dot.ant-spin-dot-spin {
    & > .ant-spin-dot-item {
      background-color: @primary_500;
    }
  }
}
