@import "~/styles/colors.less";
@import "~/styles/responsive.less";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 78px;

  & > .modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    background-color: @background_900;
    z-index: 10;
    padding: 16px 24px;
    transition: all 0.25s linear;
    transform: translateX(100%);
  }

  &-bg {
    // position: sticky;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: @background;
  }

  &-close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }

  &-left,
  &-right {
    display: flex;
    align-items: center;
    column-gap: 60px;
    flex: 1;
  }

  &-right {
    justify-content: flex-end;
  }

  &-item {
    display: flex;
    column-gap: 16px;
    align-items: center;
    cursor: pointer;

    & > .text {
      color: @background_20;
      font-weight: 600;
      font-size: 14px;
      flex: 1;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &:hover {
      .icon {
        color: @primary_500;
      }

      .text {
        color: @primary_500;
      }
    }
  }

  &-mobile {
    display: none;
    gap: 24px;

    & > .icon {
      cursor: pointer;
    }
  }

  @media screen and (max-width: @tablet) {
    justify-content: space-between;

    &-left,
    &-right {
      display: none;
    }

    &-mobile {
      display: flex;
    }
  }

  @media screen and (max-width: @medium-mobile) {
    &-bg {
      position: relative;
    }
  }
}

@primary-color: #E2E2E2;