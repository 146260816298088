@import "~/styles/colors.less";
@import "~/styles/responsive.less";

.chat-modal {
  position: fixed;
  bottom: 20px;
  right: 40px;
  flex-direction: column;
  border-radius: 12px;
  width: 442px;
  height: 664px;
  z-index: 999;
  overflow: hidden;
  display: none;

  &.active {
    display: flex;
  }

  &-header {
    display: flex;
    align-items: center;
    column-gap: 16px;
    background-color: @primary_700;
    padding: 8px 24px;
    height: 52px;

    & > .left {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: white;
      flex: 1;
      margin-bottom: 0;
    }

    & > .right {
      display: flex;
      align-items: center;
      column-gap: 16px;

      & > .action {
        font-size: 16px;
        line-height: 24px;
        color: @red;
        cursor: pointer;
        margin-bottom: 0;
      }
    }
  }

  &-body {
    flex: 1;
    overflow: hidden;
    position: relative;
    background-color: white;

    .loading {
      position: absolute;
      inset: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
    }

    .message_container {
      display: flex;
      align-items: flex-end;
      column-gap: 0.5rem;
      padding: 8px 24px;

      &.mine {
        flex-direction: row-reverse;

        & > .message_content {
          align-items: flex-end;

          .description {
            background-color: @primary_500;
          }
        }
      }

      & > .avatar {
        margin-bottom: 26px;
        object-fit: cover;
      }

      & > .message_content {
        display: flex;
        flex-direction: column;
        flex: 1;
        row-gap: 0.5rem;
        align-items: flex-start;

        .description {
          background-color: @background_50;
          padding: 12px;
          white-space: pre-line;
          overflow-wrap: anywhere;
          max-width: 80%;
          border-radius: 12px;
          margin-bottom: 0;

          span {
            font-size: 14px;
            color: white;
          }

          a {
            font-size: 14px;
            color: white;
            text-decoration: underline;
          }
        }

        .date {
          font-size: 12px;
          color: @background_1050;
        }
      }
    }

    .product {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding: 8px 24px;

      &_text {
        text-align: center;
        font-size: 14px;
        color: @black_50;
        margin-bottom: 0;
      }

      &_box {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        padding: 12px;
        background-color: @background_50;
        border-radius: 12px;

        .description {
          word-break: break-word;
          white-space: pre-wrap;
          color: white;
          font-size: 14px;
          margin-bottom: 0;
        }
      }

      &_content {
        display: flex;
        column-gap: 8px;

        .image {
          width: 56px;
          height: 56px;
          border-radius: 8px;
          object-fit: cover;
        }

        .name {
          font-size: 14px;
          color: white;
          font-weight: 500;
          margin-bottom: 0;
        }

        .price {
          font-size: 14px;
          font-weight: 600;
          color: @primary_500;
        }

        .see_more {
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          color: @primary_500;
          cursor: pointer;
          margin-bottom: 0;
        }
      }
    }
  }

  &-footer {
    padding: 8px 24px;
    // height: 72px;
    background-color: @white_100;
  }

  .block {
    background-color: @red;
    padding: 8px 24px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
  }

  @media screen and (max-width: @tablet) {
    width: 100%;
    height: 100%;
    inset: 0;
    border-radius: 0;
  }
}

@primary-color: #E2E2E2;