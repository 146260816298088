@import "~/styles/colors.less";

.button {
  // border-radius: 12px;
  border-radius: 8px;
  width: 100%;
  min-height: 46px;
  cursor: pointer;
  outline: none !important;

  &.small {
    padding: 2px 6px;
    min-height: 28px;

    .text {
      font-size: 12px;
    }
  }

  &.middle {
    padding: 12px 16px;
  }

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }

  &-children {
    display: flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;

    & > .text {
      font-size: 14px;
      font-weight: 600;
      color: @white;
    }
  }

  &.primary {
    // background: @gradient;
    background: @primary_500;
  }

  &.secondary {
    background: @background_150;
  }

  &.primary_outline {
    border: 1px solid @primary_500;
    background: transparent;

    .text {
      color: @primary_500;
    }

    &:hover {
      background: @primary_500;

      .text {
        color: @white;
      }

      .icon {
        color: @white;
      }
    }
  }

  &.outline {
    border: 1px solid @background_100;

    .text {
      color: @background_100;
    }
  }

  &.danger {
    background: @red;
  }

  &.tertiary {
    background: @background_800;
    cursor: not-allowed;
  }
}

@primary-color: #E2E2E2;