@import "~/styles/colors.less";

.header-privacy {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background: @background;
  width: 100%;
}

@primary-color: #E2E2E2;