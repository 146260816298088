@import "~/styles/colors.less";
@import "~/styles/responsive.less";

.input-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;

  &.disabled .input {
    background-color: @background_700;
    cursor: not-allowed;
  }

  & > .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: @white;
  }

  &.quaternary {
    &.disabled .input {
      background-color: @background_650;

      input {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    & > .label {
      color: @black_50;
    }
  }

  & > .input {
    display: flex;
    column-gap: 16px;
    align-items: center;
    padding: 8px 16px;
    border-radius: 12px;
    height: 56px;

    input {
      width: 100%;
      font-size: 14px;
      color: @white;
      outline: none;
      background-color: transparent;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }

      &::placeholder {
        font-size: 14px;
        color: @background_800;
      }

      &::selection {
        background-color: @background_100;
      }
    }

    // .icon svg {
    //   color: @background_800;
    // }

    &.primary {
      border: 1px solid @background_700;

      &.focused {
        border-color: @primary_500;

        .icon svg {
          color: @primary_500;
        }
      }
    }

    &.primary-outline {
      border: 1px solid @background_400;

      input {
        &::placeholder {
          color: @background_400;
        }
      }

      .icon svg {
        color: @background_400;
      }
    }

    &.secondary {
      background-color: @background_800;

      input {
        &::placeholder {
          color: @background_400;
        }
      }

      .icon svg {
        color: @white;
      }
    }

    &.secondary.focused {
      background-color: @background_900;
    }

    &.tertiary {
      background-color: @primary_700;
    }

    &.quaternary {
      border: 1px solid @background_1100;

      &.focused {
        border-color: @primary_500;

        .icon svg {
          color: @primary_500;
        }
      }

      input {
        color: @black_50;

        &::placeholder {
          color: @background_100;
        }
      }

      .icon svg {
        color: @background_100;
      }
    }
  }

  & > .error-message {
    color: @red;
    font-size: 12px;
    font-weight: 500;
  }
}

@primary-color: #E2E2E2;