@font-face {
  font-family: "Vintage Smooth";
  src: url("../assets/fonts/Vintage_Typeface.woff2") format("woff2"),
    url("../assets/fonts/Vintage_Typeface.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Norwester";
  src: url("../assets/fonts/Norwester-Regular.woff2") format("woff2"),
    url("../assets/fonts/Norwester-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
