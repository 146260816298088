@import "~/styles/colors.less";

.ant-modal {
  &-content {
    border-radius: 12px !important;
    overflow: hidden !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &-content &-header {
    background-color: @background;
    border-bottom: none;

    & > .ant-modal-title {
      font-size: 18px;
      font-weight: 500;
      color: @white;
    }
  }

  &-content &-body {
    background-color: @background;
  }

  &-content &-footer {
    background-color: @background;
    padding: 0 24px 24px 24px;
    border-top: none;
  }

  &.secondary {
    .ant-modal-content .ant-modal-header {
      background-color: @background_900;
    }

    .ant-modal-content .ant-modal-body {
      background-color: @background_900;
    }

    .ant-modal-content .ant-modal-footer {
      background-color: @background_900;
    }
  }
}

@primary-color: #E2E2E2;